<template>
  <div class="ecosystem-create">
    <v-container fluid fill-height>
      <c-dashboard-nav></c-dashboard-nav>

      <v-container class="ecosystem-create__content">
        <v-row justify="center">
          <v-col class="d-flex flex-column">
            <!-- Header -->
            <c-page-heading
              class="text-left"
              :heading="$t('ecosystems.create.create')"
              :subHeading="$t('ecosystems.create.ecosystem')"
              style="margin-left: -3px"
            ></c-page-heading>

            <span class="subheading" data-cy="createAEcosystemForYourCompanyOrTeam">{{
              $t('ecosystems.create.createAEcosystemForYourCompanyOrTeam')
            }}</span>

            <!-- Content -->
            <v-row>
              <v-col sm="12" md="10" lg="8">
                <c-ecosystem-form
                  v-model="ecosystem"
                  :disableSubmit="disableSubmit"
                  :submitLabel="$t('ecosystems.create.button')"
                  @submit="onSubmit"
                  @file-selected="onFileSelect"
                  @file-delete="onFileDelete"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <c-ecosystem-creating-modal
      :modal="creatingModal"
      @explore-spaces="$router.push(`/ws/${ecosystem.id}/spaces`)"
      @manage-ecosystem="$router.push(`/ws/${ecosystem.id}/edit`)"
    />
  </div>
</template>
<script>
import { sleep } from '@/utilities/functions'

export default {
  data() {
    return {
      ecosystem: {
        name: '',
        timeZone: '',
        style: {
          primaryColour: null,
          secondaryColour: null,
          logos: [],
        },
      },
      files: {},
      uploadedFiles: [],
      creatingModal: {
        show: false,
        stepDelay: 2000,
        steps: [
          {
            number: 1,
            text: this.$t('ecosystems.create.settingUpBackend'),
            status: 'loading',
            handler: this.createEcosystem,
          },
          {
            number: 2,
            text: this.$t('ecosystems.create.uploadingFiles'),
            status: 'loading',
            handler: this.uploadFiles,
          },
          {
            number: 3,
            text: this.$t('ecosystems.create.updatingEcosystem'),
            status: 'loading',
            handler: this.updateStyle,
          },
        ],
        complete: false,
        hasFailed: false,
      },
      disableSubmit: false,
    }
  },
  methods: {
    /**
     * The submit handler that fires when the user sumbmits the ecosystem form
     */
    async onSubmit() {
      this.$store.dispatch('ui/closeSnackbar')

      this.disableSubmit = true
      this.creatingModal.show = true

      const { steps, stepDelay } = this.creatingModal

      // Perform the steps to create an ecosystem
      for (let step of steps) {
        try {
          await step.handler()

          await sleep(stepDelay)

          step.status = 'success'
        } catch (message) {
          console.error(message)
          this.markStepsAsFailed(step)

          await sleep(stepDelay)

          // Abort
          break
        }
      }

      this.checkCreationStatus()

      // re-enable submit
      this.disableSubmit = false
    },
    /**
     * A function to create an ecosystem
     */
    async createEcosystem() {
      const { id } = await this.$store.dispatch('ecosystems/create', this.ecosystem)
      this.ecosystem.id = id
    },
    /**
     * A function to upload files to an ecosystem
     */
    async uploadFiles() {
      if (!Object.values(this.files).length > 0) {
        return
      }

      const response = await this.$store.dispatch('files/upload', {
        ecosystemId: this.ecosystem.id,
        files: Object.values(this.files),
      })

      const files = response.data.data

      // Check if success
      if (files.some(file => !file.succeeded)) {
        throw 'File upload failed'
      }

      this.uploadedFiles = Object.keys(this.files).map((k, i) => ({
        ...files[i],
        // Store the index so we know which logo to assign this file too
        index: parseInt(k.charAt(k.length - 1)) - 1,
      }))
    },
    /**
     * A function to get the styles for an ecosystem
     */
    async getStyle() {
      let data = await this.$store.dispatch('ecosystems/getStyle', {
        ecosystemId: this.ecosystem.id,
      })

      return {
        ...this.ecosystem.style,
        ...data,
      }
    },
    /**
     * A function to update the styles for an ecosystem
     */
    async updateStyle() {
      // Get all the branding information for this ecosystem
      let style = await this.getStyle()

      // Assign the recently uploaded file to the branding
      this.uploadedFiles.forEach(file => {
        style.logos[file.index].textureId = file.id
      })

      // Set the colours
      style.primaryColour = this.ecosystem.style.primaryColour
      style.secondaryColour = this.ecosystem.style.secondaryColour

      const updatedEcosystemStyle = await this.$store.dispatch('ecosystems/updateStyle', {
        ecosystemId: this.ecosystem.id,
        data: style,
      })

      // Update the ecosystem style object
      this.ecosystem.style = updatedEcosystemStyle
    },
    /**
     * Launches a toast when the ecosystem has failed to be created
     */
    async notifyEcosystemFailed() {
      // wait some time
      await sleep(this.creatingModal.stepDelay)

      this.creatingModal.show = false
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'error',
        message: this.$t('ecosystems.create.ecosystemHasNotBeenCreated'),
        subMessage: this.$t(
          'ecosystems.create.thereHasBeenAnIssueProcessingYourRequestPleaseTryAgain'
        ),
        buttonColor: 'white',
        icon: 'mdi-alert-octagon',
      })
    },
    /**
     * Checks each step for success/fail and notifies the user
     */
    checkCreationStatus() {
      // If the first step failed no ecosystem was created
      if (this.creatingModal.steps[0].status !== 'success') {
        this.notifyEcosystemFailed()
      }

      // Determine if all the steps have completed successfully
      if (this.creatingModal.steps.every(step => step.status === 'success')) {
        this.creatingModal.complete = true
      } else {
        this.creatingModal.hasFailed = true
      }
    },
    /**
     * Fires when a file is selected
     */
    onFileSelect({ file, banner }) {
      this.files[banner.id] = file
    },
    /**
     * Triggers when a creation step fails
     */
    markStepsAsFailed(currentStep) {
      const { steps } = this.creatingModal
      const index = currentStep.number - 1

      // Mark rest of the steps as failure
      steps.slice(index, steps.length).forEach(step => {
        step.status = 'failure'
      })
    },
    onFileDelete(banner) {
      this.$delete(this.files, banner.id)
    },
  },
}
</script>

<style lang="scss">
.ecosystem-create__content {
  font-family: Mulish !important;
  gap: 24px;

  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }
  @media (min-width: 1264px) {
    max-width: 1120px;
  }
  @media (min-width: 1904px) {
    max-width: 1240px;
  }

  form.v-form {
    margin: -16px;
  }
}

.card {
  margin-bottom: 24px;
}

.subheading {
  margin-top: -48px;
  margin-bottom: 32px;
  font-size: 1.285rem;
  line-height: 1.5;
}
</style>
